<template>
  <div>
    <ClientHeader :client="client" />
    <v-container>
      <v-dialog v-model="submitDialog" max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="text-large text-md-h6"
              >Submit mandates for a Review by a Tax Attorney</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p class="text-h5 font-weight-bold red--text">{{ submitDialogText }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row dense justify="space-between">
              <v-col cols="auto">
                <v-btn @click="closeSubmitDialog" text color="primary">Close</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :loading="submissionLoading"
                  @click="submitClientMandates"
                  id="confirm-client-mandates-submit-btn"
                  text
                  color="primary"
                  >Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row style="height: 100vh; display: flex">
        <v-col :id="selectedMandatePdf ? 'firstColumn' : ''" md="6">
          <v-col cols="12">
            <ClientMandates
              type="Submitted"
              :mandates="submittedMandates"
              :setActiveMandateId="setActiveMandateId"
              :activeMandateId="activeMandateId"
              @renderPdf="renderPdf"
              :loading="submittedMandatesLoading"
            />
          </v-col>
          <v-col cols="12">
            <ClientMandates
              type="Selected"
              :mandates="selectedMandates"
              btnText="Remove"
              :btnAction="removeFromSelectedMandates"
              :submitBtnAction="openSubmitDialog"
              :setActiveMandateId="setActiveMandateId"
              :activeMandateId="activeMandateId"
              @renderPdf="renderPdf"
            />
          </v-col>
          <v-col cols="12">
            <ClientMandates
              type="Suggested"
              :mandates="suggestedMandates"
              btnText="Add Mandate"
              :btnAction="moveToSelectedMandates"
              :setActiveMandateId="setActiveMandateId"
              :activeMandateId="activeMandateId"
              :loading="suggestedMandatesLoading"
              @renderPdf="renderPdf"
            />
          </v-col>
          <v-col cols="12">
            <v-row justify="center" align="center">
              <v-col cols="auto" md="9">
                <v-text-field v-on:keyup.enter="getMandates" v-model="searchTerm" label="restaurants houston tx"></v-text-field>
              </v-col>
              <v-col cols="auto" class="d-flex justify-md-end align-center" md="3">
                <v-btn @click="getMandates" color="primary">Search</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              v-if="searchLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <ClientMandates
              btnText="Add Mandate"
              :btnAction="moveToSelectedMandates"
              @renderPdf="renderPdf"
              :setActiveMandateId="setActiveMandateId"
              :activeMandateId="activeMandateId"
              v-if="searchedMandates.length"
              :mandates="searchedMandates"
            />
          </v-col>
        </v-col>

        <v-col
          cols="6"
          id="secondColumn"
          class="text-center"
          v-if="!isMobile && selectedMandatePdf"
        >
          <v-btn @click="downloadMandate" color="primary" class="mb-5">
            Download
            <v-icon right dark> mdi-download</v-icon>
          </v-btn>
          <MandatePDF :pdf="selectedMandatePdf"></MandatePDF>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MandatePDF from "@/components/Mandates/MandatePDF.vue";
import ClientMandates from "@/components/Mandates/ClientMandates.vue";
import ClientsService from "@/services/clients.service";
import MandateService from "@/services/mandate.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "Mandates",
  components: { ClientHeader, MandatePDF, ClientMandates },
  data: () => ({
    selectedMandatePdf: null,
    selectedMandates: [],
    searchTerm: "",
    searchLoading: false,
    submitDialog: false,
    submitDialogText: "",
    activeMandateId: undefined,
    submissionLoading: false,
    clientId: undefined,
    clientNaicsCode: undefined,
    clientState: undefined,
    suggestedMandatesLoading: false,
    submittedMandatesLoading: false,
    suggestedMandates: [],
    searchedMandates: [],
    submittedMandates: [],
    client: null,
  }),
  methods: {
    renderPdf(pdf) {
      this.selectedMandatePdf = pdf;
    },
    deleteFromArray(arr, i) {
      arr.splice(i, 1);
    },
    pushToArray(arr, element) {
      arr.push(element);
    },
    moveToSelectedMandates(mandate, i) {
      if (mandate.suggested) this.deleteFromArray(this.suggestedMandates, i);

      if (!this.selectedMandates.includes(mandate))
        this.pushToArray(this.selectedMandates, mandate);
    },
    removeFromSelectedMandates(mandate, i) {
      this.deleteFromArray(this.selectedMandates, i);

      if (mandate.suggested) this.pushToArray(this.suggestedMandates, mandate);
    },
    openSubmitDialog() {
      if (this.selectedMandates.length < 3) {
        this.submitDialogText =
          "Are you sure you want to submit less than 3 mandates for Tax Attorney review?";
      } else {
        this.submitDialogText =
          "Yes I want to submit these mandates for a Review by a Tax Attorney";
      }
      this.submitDialog = true;
    },
    closeSubmitDialog() {
      this.submitDialogText = "";
      this.submitDialog = false;
    },
    submitClientMandates() {
      let mandateIds = this.selectedMandates.map((obj) => obj.id);
      this.submissionLoading = true;
      let payload = {
        mandates: mandateIds,
      };

      ClientsService.postQualification(this.clientId, payload).finally(() => {
        this.submissionLoading = false;
        this.selectedMandates = [];
        this.suggestedMandates = [];
        this.searchedMandates = [];
        this.getSubmittedMandates();
        this.closeSubmitDialog();
      });
    },
    downloadMandate() {
      window.open(this.selectedMandatePdf, "_blank");
    },
    getMandates() {
      if (!this.searchTerm) return;
      this.searchLoading = true;
      MandateService.searchMandates(this.searchTerm)
        .then((res) => {
          this.searchedMandates = res;
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
    getSuggestedMandates() {
      this.suggestedMandatesLoading = true;

      MandateService.searchMandates(this.clientState)
        .then((res) => {
          res.forEach((obj) => {
            obj.suggested = true;
          });
          this.suggestedMandates = res;
        })
        .finally(() => (this.suggestedMandatesLoading = false));
    },
    setActiveMandateId(id) {
      this.activeMandateId = id;
    },
    getClientData() {
      return ClientsService.getOneClient(this.clientId).then((res) => {
        this.clientNaicsCode = res.naics_code;
        this.clientState = res.state;
        this.client = res;
      });
    },
    getSubmittedMandates() {
      this.submittedMandatesLoading = true;
      ClientsService.getMandates(this.clientId)
        .then((res) => {
          this.submittedMandates = res;
        })
        .finally(() => this.submittedMandatesLoading = false)
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    await this.getClientData();
    this.getSubmittedMandates();
    this.getSuggestedMandates();
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"
    );
    // recaptchaScript.setAttribute("src", "//mozilla.github.io/pdf.js/build/pdf.js");
    document.head.appendChild(recaptchaScript);
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped>
#firstColumn {
  height: 100%;
  width: 45%;
  //border:1px solid red; float: left; overflow-y: scroll;
}

#secondColumn {
  height: 100%;
  width: 45%;
  //margin-left:50%; //border:1px solid blue; overflow-y: scroll;
}

@media only screen and (min-width: 960px) {
  #firstColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
  }

  #secondColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
}
</style>