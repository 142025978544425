<template>
  <v-sheet elevation="1" class="py-4 px-5" rounded="xl" outlined>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <p class="text-h6 d-inline">{{ headerText }}</p>
        <v-btn
          @click="submitBtnAction"
          v-if="type === 'Selected' && mandates.length"
          color="primary"
          id="submit-client-mandates-btn"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <br>
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="type === 'Submitted' && mandates.length">
      <v-col cols="12">
          <v-list>
            <template v-for="mandate in mandates">
              <v-list-item :class="{ activeSubmitted: activeMandateId === mandate.id }" :key="mandate.id">
                <v-list-item-action>
                  <v-icon color="primary">mdi-label</v-icon>
                </v-list-item-action>
                <v-list-item-content
                  style="cursor: pointer"
                  class="text-decoration-underline"
                  @click="handleClick(mandate.upload_url, mandate.id)"
                >
                  <v-list-item-title >{{ mandate.original_file_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
      </v-col>
    </v-row>
    <v-row v-else class="mt-3" dense v-for="(mandate, i) in mandates" :key="i">
      <v-col cols="12" md="12" xs="12" sm="12">
        <v-card
          :class="{ active: activeMandateId === mandate.id }"
          elevation="1"
          @click.native="handleClick(mandate.upload_url, mandate.id)"
          rounded="lg"
          outlined
        >
          <v-card-text style="color: black" class="text-center pa-0 font-weight-medium">
            <v-row dense>
              <v-col cols="12">
                <div
                  :style="'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'"
                  class="d-flex flex-column px-2 py-5 fill-height"
                >
                  <v-skeleton-loader type="text" v-if="!mandate.state_code" />
                  {{ mandate.state_code }}
                </div>
              </v-col>
              <v-col cols="12">
                <div
                  class="d-flex justify-center flex-column fill-height pa-2"
                  :style="'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'"
                >
                  <v-skeleton-loader type="text" v-if="!mandate.counties" />
                  <p
                    class="text-caption"
                    style="color: rgb(128, 128, 128)"
                    v-if="mandate.counties && !mandate.counties.length"
                  >
                    No data for counties
                  </p>
                  <div v-else v-for="(county, i) in mandate.counties" :key="`C${i}`">
                    {{ county.county_name }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div
                  class="d-flex justify-center flex-column fill-height pa-2"
                  :style="'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'"
                >
                  <v-skeleton-loader type="text" v-if="!mandate.cities" />
                  <p
                    class="text-caption"
                    style="color: rgb(128, 128, 128)"
                    v-else-if="mandate.cities && !mandate.cities.length"
                  >
                    No data for cities
                  </p>
                  <div v-else v-for="(city, i) in mandate.cities" :key="`CI${i}`">
                    {{ city.name }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-center flex-column fill-height pa-2">
                  <v-skeleton-loader type="text" v-if="!mandate.naics" />
                  <p
                    class="text-caption"
                    style="color: rgb(128, 128, 128)"
                    v-else-if="mandate.naics && !mandate.naics.length"
                  >
                    No data for industries
                  </p>
                  <div v-else v-for="(naic, i) in mandate.naics" :key="`N${i}`">
                    {{ naic.code }}
                  </div>
                </div>
              </v-col>
              <v-col class="py-4" cols="12" align-self="center">
                <v-btn @click.prevent="btnAction(mandate, i)" color="primary">{{ btnText }} </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-if="type === 'Selected' && !mandates.length">
      <v-col cols="12">
        <v-alert type="error">Please select at least one mandate to submit.</v-alert>
      </v-col>
    </v-row>
    <v-row dense v-else-if="type === 'Suggested' && !mandates.length && !loading">
      <v-col cols="12"
        ><p class="text-caption text-center">
          ALL MANDATES ARE SELECTED OR NONE FOUND FOR THIS CLIENT
        </p>
      </v-col>
    </v-row>
    <v-row dense v-else-if="type === 'Submitted' && !mandates.length && !loading">
      <v-col cols="12">
        <p class="text-caption text-center">
          THERE ARE NO SUBMITTED MANDATES YET
        </p>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "ClientMandates",
  props: [
    "mandates",
    "type",
    "btnText",
    "btnAction",
    "submitBtnAction",
    "setActiveMandateId",
    "activeMandateId",
    "loading",
  ],
  methods: {
    handleClick(pdf, id) {
      this.$emit("renderPdf", pdf);
      this.setActiveMandateId(id);
    },
  },
  computed: {
    headerText() {
      switch (this.type) {
        case "Selected":
          return `Selected Mandates - ${this.mandates.length}`;
        case "Suggested":
          return "Suggested Mandates";
        case 'Submitted':
          return `Submitted Mandates - ${this.mandates.length}`;
        default:
          return "Search result";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped>
.active {
  box-shadow: 1px 7px 8px -1px #263B4F !important;
  -webkit-box-shadow: 1px 7px 8px -1px #263B4F !important;
  -moz-box-shadow: 1px 7px 8px -1px #263B4F !important;
}

.activeSubmitted {
  border: 1px dotted #263B4F;
}
</style>